export default class formField {
  constructor ($fields) {
    this.$formRow = $fields.closest('.formRow')
    if (this.$formRow.length < 1) {
      this.$formRow = $fields.closest('.formRowRepresentative')
    }
    this.$field = this.$formRow.find('input, select')
    if (this.$field) {
      this.$field = $fields
    }

    this.$formError = this.$formRow.find('.formError')
    if (this.$formError.length > 1) {
      this.$formError = this.$formError.eq(this.$formError.length - 1)
    }

    this.$errorIndicatorfield = this.$field.closest('.formErrorIndicator', this.$formRow)

    if (this.$errorIndicatorfield.length < 1) {
      this.$errorIndicatorfield = this.$field
    }
    this.$field.on('blur', this.handleFieldBlur.bind(this))
    this.$field.on('focus', this.handleFieldFocus.bind(this))
  }

  handleFieldBlur (event) {
    this.resetValidation()
  }

  handleFieldFocus (event) {
    this.resetValidation()
  }

  resetValidation () {
    this.$errorIndicatorfield.removeClass('is-invalid')
    this.$errorIndicatorfield.removeClass('is-valid')
    this.$formError.text('')
  }

  error (message) {
    this.resetValidation()
    this.$errorIndicatorfield.addClass('is-invalid')
    this.$formError.text(message)
  }

  valid () {
    this.resetValidation()
    this.$errorIndicatorfield.addClass('is-valid')
  }

  setValue (value) {
    // console.log(this)
    const tagName = this.$field.prop('tagName').toLowerCase()
    const type = this.$field.attr('type')

    switch (tagName) {
      case 'input':
        switch (type) {
          case 'hidden':
          case 'text':
          case 'email':
          case 'password':
            this.$field.val(value)
            break
          case 'checkbox':
            this.$field.prop('checked', (value === this.$field.attr('value')))
            break
          default:
            console.error(`setValue for "${tagName}"/"${type}" is not implemented`)
            break
        }
        break
      case 'select':
        this.$field.val(value)
        break
      case 'default':
        console.error(`setValue for "${tagName} is not implemented`)
        break
    }
  }
}
