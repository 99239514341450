import $ from 'jquery'
import Behaviors from './../behaviors'

export default class autoReload {
  constructor (element) {
    this.$element = $(element)
    this.$checkbox = this.$element.find('#autoReloadCheckbox')
    this.targetId = this.$element.data('reloadTarget')
    this.storageId = this.$element.data('storageId')
    this.$target = $(`#${this.targetId}`)
    this.autoReload = false
    this.loadInProgress = false
    this.reloadInterval = parseInt(this.$element.data('updateInterval'), 10) || 10000 // time to wait between updates
    this.$checkbox.on('change', this.handleCheckboxChange.bind(this))

    this.storageConfig = this.loadStorageConfig()
    this.$checkbox
      .prop('checked', this.storageConfig.autoReloadEnabled)
      .trigger('change')
  };

  loadStorageConfig () {
    let storage = window.localStorage.getItem(this.storageId)
    if (storage) {
      try {
        storage = JSON.parse(window.localStorage.getItem(this.storageId))
      } catch (e) {
        // do nothing
      }

      // gültig!
      if (storage.configVersion === 1) {
        return storage
      }
    }

    return {
      configVersion: 1,
      autoReloadEnabled: false
    }
  }

  saveStorageConfig () {
    window.localStorage.setItem(this.storageId, JSON.stringify(this.storageConfig))
  }

  handleCheckboxChange (event) {
    if (this.$checkbox.prop('checked')) {
      this.autoReload = true
      this.doAutoReload()
      this.storageConfig.autoReloadEnabled = true
    } else {
      this.autoReload = false
      this.storageConfig.autoReloadEnabled = false
    }
    this.saveStorageConfig()
  }

  doAutoReload () {
    if (this.loadInProgress) {
      return
    }

    this.loadInProgress = true

    const url = window.location.href

    $.get(url, function (data, xhr, status) {
      const scrollTop = this.$target.scrollTop()
      const scrollLeft = this.$target.scrollLeft()
      Behaviors.detachBehaviors(this.$target.get(0))

      // If a selector was specified, locate the right elements in a dummy div
      // Exclude scripts to avoid IE 'Permission Denied' errors
      this.$target.html(
        $('<div>').append($.parseHTML(data)).find(`#${this.targetId}`).html()
      )
      this.$target = $(`#${this.targetId}`)

      if (status === 'error') {
        var msg = 'Sorry but there was an error: '
        this.$target.html(msg + xhr.status + ' ' + xhr.statusText)
      }

      this.$target.scrollLeft(scrollLeft)
      this.$target.scrollTop(scrollTop)
      Behaviors.attachBehaviors(this.$target.get(0))
      this.loadInProgress = false
      window.setTimeout(() => {
        if (this.autoReload) {
          this.doAutoReload()
        }
      }, this.reloadInterval)
    }.bind(this))
      .done(function () {
      })
      .fail(function () {
      })
      .always(function () {
      })
  }
}
