import $ from 'jquery'
import Collapse from 'bootstrap/js/src/collapse'

export default class asyncJobTool {
  constructor (element) {
    this.$element = $(element)
    this.loadInProgress = false

    this.$allJobs = this.$element.find('.allJobs')
    this.$doneJobs = this.$element.find('.doneJobs')
    this.$waitingJobs = this.$element.find('.waitingJobs')
    this.$closedJobs = this.$element.find('.closedJobs')
    this.$errorJobs = this.$element.find('.errorJobs')
    this.$openJobs = this.$element.find('.openJobs')
    this.$runningJobs = this.$element.find('.runningJobs')

    this.$runningJobIndicator = this.$element.find('.runningJobIndicator')
    this.$daemonRunningContainer = this.$element.find('.daemonRunningContainer')
    this.$daemonIsRunning = this.$daemonRunningContainer.find('.daemonIsRunning')
    this.$daemonIsNotRunning = this.$daemonRunningContainer.find('.daemonIsNotRunning')
    this.$progressBarSuccess = this.$element.find('.progress .progressBarSuccess')
    this.$progressBarError = this.$element.find('.progress .progressBarError')
    this.$progressBarRunning = this.$element.find('.progress .progressBarRunning')
    this.$progressBarWaiting = this.$element.find('.progress .progressBarWaiting')

    this.reloadIntervalOpen = parseInt(this.$element.data('updateInterval'), 10) || 10000 // 5 seconds
    this.reloadIntervalClosed = 10 * 1000 // 10 seconds
    this.url = this.$element.data('asyncJobUrl')

    this.targetSelector = this.$element.data('collapseTarget')
    this.$target = $(this.targetSelector)
    this.$target.get(0).addEventListener('hide.bs.collapse', this.handleCollapseHidden.bind(this))
    this.$target.get(0).addEventListener('show.bs.collapse', this.handleCollapseShown.bind(this))

    this.storageConfig = this.loadStorageConfig()
    if (this.storageConfig.flyoutOpenState) {
      this.collapse = new Collapse(this.$target.get(0))
      this.collapse.show()
    }

    this.refreshTimeout = null

    this.refresh()
  }

  loadStorageConfig () {
    let storage = window.localStorage.getItem('footerToolAsyncJobWindowOpen')
    if (storage) {
      try {
        storage = JSON.parse(window.localStorage.getItem('footerToolAsyncJobWindowOpen'))
      } catch (e) {
        // do nothing
      }

      // gültig!
      if (storage.configVersion === 1) {
        return storage
      }
    }

    return {
      configVersion: 1,
      flyoutOpenState: false
    }
  }

  saveStorageConfig () {
    window.localStorage.setItem('footerToolAsyncJobWindowOpen', JSON.stringify(this.storageConfig))
  }

  handleCollapseShown (event) {
    this.storageConfig.flyoutOpenState = true
    this.saveStorageConfig()
    this.clearTimer()
    this.refresh()
  }

  handleCollapseHidden (event) {
    this.storageConfig.flyoutOpenState = false
    this.saveStorageConfig()
    this.clearTimer()
    this.refresh()
  }

  clearTimer () {
    if (this.refreshTimeout !== null) {
      window.clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  refresh () {
    if (this.loadInProgress) {
      return
    }

    this.loadInProgress = true

    $.get(this.url, function (response, status, xhr) {
      if (status === 'error') {
        var msg = 'Sorry but there was an error: '
        this.$target.html(msg + xhr.status + ' ' + xhr.statusText)
      }

      if (typeof response === 'object' && !Array.isArray(response) && response !== null) {
        this.updateUI(response)
      }
    }.bind(this)).always(function () {
      this.loadInProgress = false
      this.clearTimer()
      this.refreshTimeout = window.setTimeout(() => {
        this.refresh()
      }, (this.storageConfig.flyoutOpenState ? this.reloadIntervalOpen : this.reloadIntervalClosed))
    }.bind(this))
  }

  updateUI (responseData) {
    this.$daemonRunningContainer.show()
    if (responseData.hasOwnProperty('daemonRunning') && responseData.daemonRunning) {
      this.$daemonIsRunning.show()
      this.$daemonIsNotRunning.hide()
    } else {
      this.$daemonIsRunning.hide()
      this.$daemonIsNotRunning.show()
    }

    if (responseData.hasOwnProperty('runningJobs') && responseData.runningJobs > 0) {
      this.$runningJobIndicator.removeClass('disabled')
      this.$runningJobIndicator.find('i').addClass('fa-spin')
    } else {
      this.$runningJobIndicator.addClass('disabled')
      this.$runningJobIndicator.find('i').removeClass('fa-spin')
    }

    if (responseData.hasOwnProperty('allJobs')) {
      this.$allJobs.text(responseData.allJobs)
    }
    if (responseData.hasOwnProperty('doneJobs')) {
      this.$doneJobs.text(responseData.doneJobs)
    }
    if (responseData.hasOwnProperty('openJobs')) {
      this.$openJobs.text(responseData.openJobs)
    }
    if (responseData.hasOwnProperty('errorJobs')) {
      this.$errorJobs.text(responseData.errorJobs)
    }
    if (responseData.hasOwnProperty('closedJobs')) {
      this.$closedJobs.text(responseData.closedJobs)
    }
    if (responseData.hasOwnProperty('runningJobs')) {
      this.$runningJobs.text(responseData.runningJobs)
    }
    if (responseData.hasOwnProperty('waitingJobs')) {
      this.$waitingJobs.text(responseData.waitingJobs)
    }

    let progressSuccess = 0
    let progressError = 0
    let progressRunning = 0
    let progressWaiting = 0
    if (responseData.hasOwnProperty('allJobs') && responseData.allJobs) {
      if (responseData.hasOwnProperty('closedJobs') && responseData.closedJobs) {
        progressSuccess = Math.round(100 / responseData.allJobs * responseData.closedJobs)
      }
      if (responseData.hasOwnProperty('errorJobs') && responseData.errorJobs) {
        progressError = Math.round(100 / responseData.allJobs * responseData.errorJobs)
      }
      if (responseData.hasOwnProperty('runningJobs') && responseData.runningJobs) {
        progressRunning = Math.round(100 / responseData.allJobs * responseData.runningJobs)
      }
      if (responseData.hasOwnProperty('waitingJobs') && responseData.waitingJobs) {
        progressWaiting = Math.round(100 / responseData.allJobs * responseData.waitingJobs)
      }
    }
    this.$progressBarSuccess.width(`${progressSuccess}%`)
    this.$progressBarError.width(`${progressError}%`)
    this.$progressBarRunning.width(`${progressRunning}%`)
    this.$progressBarWaiting.width(`${progressWaiting}%`)
  }
}
