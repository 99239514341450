import $ from 'jquery'
import selectChoices from './selectChoices'

export default class selectChoicesUpdateFlowerInfo extends selectChoices {
    constructor (element) {
        super(element)
        this.$select.on('change.selectChoicesUpdateFlowerInfo', this.handleChange.bind(this))
        this.fetchUrl = this.$element.data('fetchUrl')
        this.widgetContainerSelector = this.$element.data('widgetContainerSelector')

        const containerClosestSelector = this.$element.data('containerClosestSelector')
        const $container = this.$element.closest(containerClosestSelector)

        const sellingUnitSelector = this.$element.data('sellingUnitSelector')
        const dosageUnitSelector = this.$element.data('dosageUnitSelector')

        this.$sellingUnit = $container.find(sellingUnitSelector)
        this.$dosageUnit = $container.find(dosageUnitSelector)

        this.$widgetContainer = $(this.widgetContainerSelector)
        this.handleChange()
    }

    handleChange () {
        const flowerId = this.$select.val()
        if (!flowerId) {
            this.$widgetContainer.html('')
            return
        }

        const url = this.fetchUrl
            .replace('_flowerId_', flowerId)

        const ajaxSettings = {
            url: url,
            method: 'post',
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            headers: {}
        }

        $.ajax(ajaxSettings)
            .done(this.handleRequestDone.bind(this))
    }

    handleResponse (status, responseData, rawData) {
        if (status === 200) {
            if (responseData.hasOwnProperty('widget')) {
                this.$widgetContainer.html(responseData.widget)
            }
            if (responseData.hasOwnProperty('sellingUnit')) {
                this.$sellingUnit.text(responseData.sellingUnit)
            }
            if (responseData.hasOwnProperty('dosageUnit')) {
                this.$dosageUnit.text(responseData.dosageUnit)
            }
        }
    }

    handleRequestDone (data, textStatus, jqXHR) {
        this.handleResponse(jqXHR.status, data, jqXHR.responseText)
    }
}
