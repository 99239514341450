import $ from 'jquery'
import Tab from 'bootstrap/js/src/tab'

export default class tabs {
  constructor (element) {
    this.$element = $(element)
    this.$navTabs = this.$element.find('.nav-link')
    this.$tabContent = this.$element.find('.tab-pane')

    // register boostrap tabs
    this.$navTabs.each(function (idx, item) {
      const tabTrigger = new Tab(item)
      $(item).data('tabTrigger', tabTrigger)
    })

    // click on tab
    this.$navTabs.on('click', this.handleTabClick.bind(this))

    // listener on url hash change
    $(window).on('hashchange', this.handleHashChange.bind(this))

    // do that initially -> if there is an hash in requesting URL
    // activate tab without pushing history state
    if (this.getHash()) {
      this.activateTab(this.getHash(), true)
      return
    }

    // activate the first tab (without pushing the state)
    this.activateTab(null, false)
  }

  // returns the URLs hash without the #
  getHash () {
    const hash = window.location.hash.replace(/^#/, '')
    return hash
  }

  /**
   * Activate tab by hashchange (mostly "browsers back") - don't push a state on hash change!
   * @param event
   */
  handleHashChange (event) {
    this.activateTab(this.getHash(), false)
  }

  /**
   * Activate a tab and push state to history
   * @param event
   */
  handleTabClick (event) {
    event.preventDefault()
    const $button = $(event.target)
    this.activateTab($button.data('tabTarget'), true)
  }

  /**
   * Activate a Tab
   * @param tabTarget string of the "target"
   * @param pushState should the new state be pushed to browsers history?
   */
  activateTab (tabTarget, pushState) {
    if (!tabTarget) {
      tabTarget = this.$navTabs.eq(0).data('tabTarget')
    }

    const $button = this.$navTabs.filter(`[data-tab-target="${tabTarget}"]`)
    if ($button.length < 1) {
      return
    }

    const $tabContent = this.$tabContent.filter(`#${tabTarget}-tab-pane`)
    if ($tabContent.length < 1) {
      return
    }

    // trigger the Boostrap-Tab
    const tabTrigger = $button.data('tabTrigger')
    tabTrigger.show()

    // hide all tabs' content
    this.$tabContent.hide()

    // show the current one
    $tabContent.show()

    // push state to history
    if (pushState) {
      window.history.pushState({}, null, `#${tabTarget}`)
    }
  }
}
