import $ from 'jquery'
import WorkflowNavigation from './workflowNavigation'

export default class workflowNavigationOnSelect extends WorkflowNavigation {
    constructor (element) {
        super(element)
        this.$workflowActionField = this.$form.find('input[name=workflowNavigation]')

        this.fieldId = this.$element.attr('id')
        this.$labelForField = $(`label[for="${this.fieldId}"]`)
        this.$labelForField.on('click', this.handleLabelClick.bind(this))

        if (this.$workflowActionField.length < 1) {
            console.error('workflowNavigation field not found!')
        }
    }

    initiateHandler () {
        this.$element.on('change.workflowNavigationOnSelect', this.handleChange.bind(this))
    }

    handleLabelClick (event) {
        // if option is already checked - we can't "recheck" it.
        // instead -> trigger a handle change (which submits the form)
        // if the option WASNT checked - tha handleEvent will be triggered by browser
        if (this.$element.prop('checked')) {
            console.log('ALREADY CHECKED!')
            this.handleChange(event)
        }
    }

    handleChange (event) {
        event.preventDefault()

        // WHY WE EVER NEEDED THIS - THIS ONE IS BULLSHIT!
        //
        // find field in workflowform
        // const fieldName = this.$element.attr('name');
        // const $workflowFormField = this.$form.find(`[type=hidden][name=${fieldName}]`)
        // if ($workflowFormField.length > 0) {
        //   if ($workflowFormField !== this.$element) {
        //     $workflowFormField.val(this.$element.val())
        //   }
        // }

        this.$workflowActionField.val(this.workflowAction)
        this.$form.trigger('submit')
    }
    destroy () {
        this.$element.off('change.workflowNavigationOnSelect')
    }
}
