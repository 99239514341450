import $ from 'jquery'
import selectChoices from './selectChoices'

export default class selectChoicesPharmacySelect extends selectChoices {
    getChoicesOptions () {
        const options = super.getChoicesOptions()
        options.callbackOnCreateTemplates = (strToEl) => {
            return {
                item: this.selectedItemTemplate.bind(this),
                choice: this.choiceTemplate.bind(this)
            }
        }
        return options
    }

    selectedItemTemplate (choices, data) {
        const classNames = choices.classNames

        const classes = [classNames.item]
        if (data.highlighted) {
            classes.push(classNames.highlightedState)
        } else {
            classes.push(classNames.itemSelectable)
        }

        return $(`
            <div class="${classes.join(' ')}"
                 data-id="${data.id}"
                 data-value="${data.value}"
                 ${(data.active ? 'aria-selected="true"' : '')}
                >
                ${this.pharmacyTemplate(data.customProperties, data.label)}
            </div>
        `).get(0)
    }

    pharmacyTemplate (properties, fallbackLabel) {
        if (typeof properties !== 'object' || properties == null) {
            return `<span>${fallbackLabel}</span>`
        }
        if (Object.keys(properties).length < 1) {
            return `<span>${fallbackLabel}</span>`
        }
        return `
            <div class="d-flex w-100 justify-content-between">
                <div class="text-truncate"><b>${properties.title}</b></div>
                <div class="d-flex ms-2 gap-1">
                    <i class="${properties.adminTypeIcon}"></i>
                    <i class="${properties.adminAvailableIcon}"></i>
                </div>
            </div>
            <div class="text-truncate">${properties.companyName}</div>
            <div class="text-truncate">${properties.addressAsString}</div>
        `
    }

    choiceTemplate (choices, data) {
        const classNames = choices.classNames

        const classes = [classNames.item, classNames.itemChoice]
        if (data.disabled) {
            classes.push(classNames.itemDisabled)
        } else {
            classes.push(classNames.itemSelectable)
        }
        if (data.selected) {
            classes.push(classNames.selectedState)
        }
        if (data.placeholder) {
            classes.push(classNames.placeholder)
        }
        classes.push('pe-0')

        return $(`
            <div class="${classes.join(' ')}" data-choice data-choice-selectable
                data-id="${data.id}"
                data-value="${data.value}"
                role="option"
                >
                ${this.pharmacyTemplate(data.customProperties, data.label)}
            </div>
        `).get(0)
    }
}
