import $ from 'jquery'

export default class approveDenyCheckbox {
  constructor (element) {
    this.$element = $(element)
    this.approveCheckboxName = this.$element.data('approveCheckboxName')
    this.denyCheckboxName = this.$element.data('denyCheckboxName')

    this.$approveCheckbox = this.$element.find(`input[type=checkbox][name='${this.approveCheckboxName}']`)
    this.$denyCheckbox = this.$element.find(`input[type=checkbox][name='${this.denyCheckboxName}']`)

    this.$approveCheckbox.on('change', this.handleApproveChange.bind(this))
    this.$denyCheckbox.on('change', this.handleDenyChange.bind(this))
  }

  handleApproveChange (event) {
    if (this.$approveCheckbox.prop('checked')) {
      this.$denyCheckbox.prop('checked', false)
      this.$denyCheckbox.trigger('change')
    }
  }
  handleDenyChange (event) {
    if (this.$denyCheckbox.prop('checked')) {
      this.$approveCheckbox.prop('checked', false)
      this.$approveCheckbox.trigger('change')
    }
  }
}
