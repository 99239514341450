import $ from 'jquery'
import Toast from 'bootstrap/js/src/toast'
import Offcanvas from 'bootstrap/js/src/offcanvas'

export default class updateToasts {
  constructor (element) {
    this.$element = $(element)

    this.$offcanvas = this.$element
    // this.$offcanvas.on('click', this.handleOffcanvasClick.bind(this))
    this.offcanvas = new Offcanvas(this.$offcanvas.get(0))

    this.$toastBarCloseButton = this.$element.find('.toastBarCloseButton')
    this.$toastBarCloseButton.on('click', this.handleOffcanvasCloseButtonClick.bind(this))

    this.toasts = []
    this.$toasts = this.$element.find('.toast')
      this.$toasts.on('click', this.handleToastSurfaceClick.bind(this))

      this.refreshTimeout = null
    this.dataUrl = this.$element.data('dataUrl')
    const targetSelector = this.$element.data('targetSelector')
    this.$targets = this.$element.find(targetSelector)
    const segmentSelector = this.$element.data('segmentSelector')
    this.$segments = this.$element.find(segmentSelector)
    this.reloadInterval = parseInt(this.$element.data('updateInterval'), 10) || 10000
    this.initToasts()
    this.refresh()
  }

  handleOffcanvasCloseButtonClick (event) {
    this.offcanvas.hide()
  }

    handleToastSurfaceClick (event) {
    if (!this.$offcanvas.hasClass('show')) {
      this.offcanvas.show()
    }
  }

  initToasts () {
    this.$toasts.each((i, toastElement) => {
      const toast = new Toast(toastElement, {
        animation: true,
        autohide: false,
        delay: 0
      })
      this.toasts.push(toast)
    })
  }

  clearTimer () {
    if (this.refreshTimeout !== null) {
      window.clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  updateUI (responseData) {
    if (responseData.hasOwnProperty('display')) {
      for (const toastName in responseData.display) {
        const currentToast = this.toasts.find((toast) => {
          return $(toast._element).data('toastName') === toastName
        })
        if (!currentToast) {
          console.error(`Toast ${toastName} not found!`)
          continue
        }
        if (responseData.display[toastName]) {
          if (!currentToast.isShown()) {
            currentToast.show()
          }
        } else {
          if (currentToast.isShown()) {
            currentToast.hide()
          }
        }
      }
    }
    this.updateSegmentUI(responseData)
    this.updateTextUI(responseData)
  }

  updateSegmentUI (responseData) {
    if (!responseData.hasOwnProperty('displaySegment')) {
      return
    }
    const displaySegment = responseData.displaySegment

    for (const segmentName in displaySegment) {
      const $currentTarget = this.$segments.filter(`.${segmentName}`)
      if ($currentTarget.length < 1) {
        console.error(`Toast Segment ${segmentName} not found!`)
        continue
      }
      $currentTarget.toggle(displaySegment[segmentName])
    }
  }

  updateSliderButtonUI () {
    // check, if ANYTHING is visble...
    const areToastsOpen = this.toasts.reduce((accumulator, toast) => {
      return (accumulator || toast.isShown())
    }, false)
  }

  updateTextUI (responseData) {
    if (!responseData.hasOwnProperty('values')) {
      return
    }
    const values = responseData.values

    for (const valueName in values) {
      const $currentTarget = this.$targets.filter(`.${valueName}`)
      if ($currentTarget.length < 1) {
        console.error(`Toast Value ${valueName} not found!`)
        continue
      }
      $currentTarget.text(values[valueName])
    }
  }

  refresh () {
    if (this.loadInProgress) {
      return
    }

    this.loadInProgress = true

    $.get(this.dataUrl, function (response, status, xhr) {
      if (typeof response === 'object' && !Array.isArray(response) && response !== null) {
        this.updateUI(response)
      }
    }.bind(this)).always(function () {
      this.loadInProgress = false
      this.clearTimer()
      this.refreshTimeout = window.setTimeout(() => {
        this.refresh()
      }, this.reloadInterval)
    }.bind(this))
  }
}
