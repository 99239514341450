import $ from 'jquery'
export default class submitOnChange {
    constructor (element) {
        this.$element = $(element)
        this.$field = this.$element.find('select, input[type=checkbox], input[type=radio]')
        this.resetAfterSubmit = this.$element.data('resetAfterSubmit')
        this.$form = this.$field.closest('form')
        this.$field.on('change', this.handleSelectChange.bind(this))
    }

    handleSelectChange (event) {
        this.$form.trigger('submit')
        if (this.resetAfterSubmit) {
            this.$field.prop('checked', false)
        }
    }

    destroy () {

    }
}
