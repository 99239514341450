import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'
import Behaviors from './../behaviors'

export default class modalLoadAjaxContent {
    constructor (element) {
        this.$element = $(element)
        this.$modal = this.$element
        this.$modalBody = this.$modal.find('.modal-body')
        this.modalDomElement = this.$modal.get(0)
        this.modal = new Modal(this.modalDomElement, {})

        this.loadingContent = this.$modalBody.html()

        this.contentUrl = this.$element.data('contentUrl')
        this.modalDomElement.addEventListener('shown.bs.modal', this.handleShown.bind(this))
        this.modalDomElement.addEventListener('show.bs.modal', this.handleShow.bind(this))
        this.loadInProgress = false
    }

    handleShown (event) {
    }

    handleShow (event) {
        this.loadContent()
    }

    loadContent () {
        if (this.loadInProgress) {
            return
        }

        this.loadInProgress = true
        this.$modalBody.html(this.loadingContent)

        $.get(this.contentUrl, function (data, xhr, status) {
            Behaviors.detachBehaviors(this.$modalBody.get(0))
            this.$modalBody.html(data)
            Behaviors.attachBehaviors(this.$modalBody.get(0))
            this.loadInProgress = false
        }.bind(this))
            .done(function () {})
            .fail(function () {})
            .always(function () {})
    }

    destroy () {
        this.$allButtons.off('click.modalAutoOpen')
    }
}
