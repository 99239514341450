import $ from 'jquery'
import FormField from '../formField'
import Modal from 'bootstrap/js/src/modal'
import SmoothScrollTo from 'flamingo-carotene-smooth-scroll-to'
import Behaviors from './../behaviors'

export default class formSubmit {
    constructor (element) {
        this.$element = $(element)
        this.$form = this.$element
        this.$buttons = this.$form.find('button')
        this.$generalFormError = this.$element.find('.generalFormError')

        this.formFields = {}
        this.initializeFields()

        this.customValidators = []
        this.customSerializer = []
        this.customResponseHandler = []

        this.formId = this.$form.attr('id')

        this.$loadingSpinner = this.$form.find('.loadingSpinner')

        this.url = this.$form.attr('action')
        this.method = this.$form.attr('method')

        this.$buttons.on('click.formSubmit', this.handleButtonClick.bind(this))
        this.$form.on('submit.formSubmit', this.handleSubmit.bind(this))

        this.$feedbackModal = $(`#${this.formId}FormModal`)
        this.feedbackModal = new Modal(this.$feedbackModal.get(0), {})
    }

    initializeFields () {
        const allNames = []

        this.$form.find('[name]').each((index, item) => {
            const $field = $(item)
            const realName = $field.attr('name')
            if (!allNames.includes(realName)) {
                allNames.push(realName)
            }
        })

        for (const name of allNames) {
            this.formFields[name] = new FormField(this.$form.find(`[name="${name}"]`))
        }
    }

    handleButtonClick (event) {
        const $button = $(event.target)
        if ($button.attr('type') === 'submit') {
            const buttonName = $button.attr('name')
            const buttonValue = $button.attr('value')
            if (buttonName && buttonValue) {
                this.$form.append(`<input type="hidden" name="${buttonName}" value="${buttonValue}">`)
            }
        }
    }

    handleSubmit (event) {
        event.preventDefault()

        if (!this.validateFrontend()) {
            this.scrollToFirstError()
            return
        }

        const formData = this.getFormData()
        // console.log('handleSubmit', formData)
        this.$generalFormError.text('')

        const ajaxSettings = {
            url: this.url,
            method: this.method,
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(formData),
            headers: {}
        }

        this.$form.addClass('submitting')

        $.ajax(ajaxSettings)
            .done(this.handleRequestDone.bind(this))
            .fail(this.handleRequestFail.bind(this))
    }

    handleRequestDone (data, textStatus, jqXHR) {
        this.handleResponse(jqXHR.status, data, jqXHR.responseText)
    }

    handleRequestFail (jqXHR, textStatus, errorThrown) {
        let data = {}
        try {
            if (jqXHR.responseText) {
                data = JSON.parse(jqXHR.responseText)
            }
        } catch (e) {
            console.error(jqXHR.responseText)
        }
        this.handleResponse(jqXHR.status, data, jqXHR.responseText)
    }

    resetValidation () {
        const fields = this.getFormData()
        for (const fieldName in fields) {
            if (this.formFields.hasOwnProperty(fieldName)) {
                this.formFields[fieldName].resetValidation()
            }
        }
    }

    handleResponse (status, responseData, rawData) {
        if (responseData.hasOwnProperty('modalsToClose')) {
            for (const closeModalId of responseData.modalsToClose) {
                const $closeModal = $(`#${closeModalId}`)
                if ($closeModal.length > 0) {
                    $closeModal.removeClass('fade')
                    const closeModal = Modal.getInstance($closeModal.get(0))
                    closeModal.hide()
                }
            }
        }

        if (status < 400) {

            if (responseData.hasOwnProperty('modalsToClose')) {
                for (const closeModalId of responseData.modalsToClose) {
                    const $closeModal = $(`#${closeModalId}`)
                    if ($closeModal.length > 0) {
                        $closeModal.removeClass('fade')
                        const closeModal = Modal.getInstance($closeModal.get(0))
                        closeModal.hide()
                    }
                }
            }

            if (responseData.hasOwnProperty('clearForm')) {
                if (responseData.clearForm) {
                    this.$form.get(0).reset()
                }
            }

            if (responseData.hasOwnProperty('showModal')) {
                const $modalTitle = this.$feedbackModal.find('.modal-title')
                const $modalBody = this.$feedbackModal.find('.modal-body')
                const $modalFooter = this.$feedbackModal.find('.modal-footer')
                const $modalButton = $modalFooter.find('button')

                $modalTitle.html(responseData.showModal.title)
                $modalBody.html(responseData.showModal.body)

                if (responseData.showModal.buttonLabel) {
                    $modalButton.text(responseData.showModal.buttonLabel)
                    $modalFooter.show()
                } else {
                    $modalFooter.hide()
                }

                Behaviors.attachBehaviors($modalBody.get(0))

                window.setTimeout(() => {
                    this.feedbackModal.show()
                    this.$form.removeClass('submitting')
                }, 2000)
                return
            }

            if (responseData.hasOwnProperty('replaceFormContentHTML')) {
                if (responseData.replaceFormContentHTML) {
                    const $currentPlace = this.$form.parent()

                    $currentPlace.html(responseData.replaceFormContentHTML)
                    Behaviors.attachBehaviors($currentPlace.get(0))
                    window.setTimeout(() => {
                        this.$form.removeClass('submitting')
                    }, 2000)
                    return
                }
            }

            if (responseData.hasOwnProperty('redirect')) {
                this.callCustomResponseHandler(true, 'redirect', { status: status, responseData: responseData, rawData: rawData })
                window.location.href = responseData.redirect
                return
            }

            if (responseData.hasOwnProperty('reload')) {
                this.callCustomResponseHandler(true, 'reload', { status: status, responseData: responseData, rawData: rawData })
                window.location.reload()
                return
            }

            const $modal = this.$form.find(`#${this.formId}FormModal`)
            const $modalBody = $modal.find('.modal-body')
            let errorContent = `<h3>Error</h3>`
            errorContent += `<p>Form Ajax response received, but no action is given.</p>`
            errorContent += `<p>You need to use "setRedirect" or "setReload" in the FormSubmitResultService.</p>`
            errorContent += rawData
            $modalBody.html(errorContent)

            const modal = new Modal($modal.get(0), {})

            modal.show()
            this.$form.removeClass('submitting')
            this.callCustomResponseHandler(false, 'fail', { status: status, responseData: responseData, rawData: rawData, errorContent: errorContent })
            return
        }

        let hasError = false
        if (responseData.hasOwnProperty('generalFormError')) {
            this.handleGeneralFormError(responseData.generalFormError)
            hasError = true
        }
        if (responseData.hasOwnProperty('fieldErrors')) {
            this.handleFieldErrors(responseData.fieldErrors)
            hasError = true
        }

        if (responseData.hasOwnProperty('suggestedFieldValues')) {
            this.handleSuggestedFieldValues(responseData.suggestedFieldValues)
            hasError = true
        }

        if (responseData.hasOwnProperty('error')) {
            hasError = true
            const $modal = this.$form.find(`#${this.formId}FormModal`)
            const $modalTitle = $modal.find('.modal-title')
            const $modalBody = $modal.find('.modal-body')

            let errorContent = responseData.error.message
            let errorHeadline = 'Form Error'
            if (status === 503) {
                const errorParts = errorContent.split('\n\n')
                if (errorParts.length > 1) {
                    errorHeadline = errorParts.shift()
                    errorContent = errorParts.join('\n\n')
                }
            }

            errorContent = `<p>${errorContent.split('\n\n').join(`</p><p>`)}</p>`
            errorContent = errorContent.split('\n---\n').join(`<hr>`)
            errorContent = errorContent.split('\n').join(`<br>`)
            if (responseData.error.hasOwnProperty('stacktrace')) {
                errorContent += `<p>${responseData.error.stacktrace.join(`<br>`)}</p>`
            }

            $modalTitle.html(errorHeadline)
            $modalBody.html(errorContent)

            const modal = new Modal($modal.get(0), {})
            modal.show()
        }

        this.callCustomResponseHandler(false, hasError ? 'error' : 'success', { status: status, responseData: responseData, rawData: rawData })

        // quickfix for workflowNavigationOnSelect behavior driven items
        this.$form.find('input[data-behavior=workflowNavigationOnSelect][type=radio]').prop('checked', false)

        this.$form.removeClass('submitting')
        this.scrollToFirstError()
    }

    handleGeneralFormError (formError) {
        if (formError) {
            this.$generalFormError.text(formError)
            this.$generalFormError.show()
        } else {
            this.$generalFormError.hide()
        }
    }

    handleFieldErrors (fieldErrors) {
        for (const fieldName in fieldErrors) {
            const message = fieldErrors[fieldName]
            if (this.formFields.hasOwnProperty(fieldName)) {
                this.formFields[fieldName].error(message)
            } else if (this.formFields.hasOwnProperty(fieldName + '[]')) {
                this.formFields[fieldName + '[]'].error(message)
            }
        }
    }

    handleSuggestedFieldValues (suggestedFieldValues) {
        for (const fieldName in suggestedFieldValues) {
            const value = suggestedFieldValues[fieldName]
            if (this.formFields.hasOwnProperty(fieldName)) {
                this.formFields[fieldName].setValue(value)
            }
        }
    }

    scrollToFirstError () {
        const visibleErrorNode = this.$form.find('.is-invalid').closest('.formRow').get(0)
        if (visibleErrorNode) {
            const headerHeight = $('header').outerHeight()
            new SmoothScrollTo()
                .setOffset(headerHeight)
                .scrollTo(visibleErrorNode)
        }
    }

    getFormData () {
        const indexedArray = {}
        const serializedArray = this.$form.serializeArray()
        // console.log('formData', serializedArray)

        $.map(serializedArray, function (n, i) {
            if (n['name'].endsWith('[]')) {
                const arrayName = n['name'].substr(0, n['name'].length - 2)
                if (!indexedArray.hasOwnProperty(arrayName)) {
                    indexedArray[arrayName] = []
                }
                indexedArray[arrayName].push(n['value'])
            } else {
                indexedArray[n['name']] = n['value']
            }
        })

        for (const serializer of this.customSerializer) {
            indexedArray[serializer.fieldName] = serializer.serialize()
        }

        return indexedArray
    }

    registerSerializer (fieldName, serializer) {
        this.customSerializer.push({ fieldName: fieldName, serialize: serializer })
    }

    registerValidator (fieldName, validator) {
        this.customValidators.push({ fieldName: fieldName, validate: validator })
    }

    registerResponseHandler (callable) {
        this.customResponseHandler.push(callable)
    }

    callCustomResponseHandler (successFull, responseType, rawData) {
    // console.log('callCustomResponseHandler')
        for (const handler of this.customResponseHandler) {
            // console.log(handler, successFull, responseType, rawData)
            handler(successFull, responseType, rawData)
        }
    }

    validateFrontend () {
        let formIsValid = true
        for (const validator of this.customValidators) {
            const error = validator.validate()
            if (error) {
                formIsValid = false
                this.formFields[validator.fieldName].error(error)
            }
        }

        return formIsValid
    }
    destroy () {
        this.$buttons.off('click.formSubmit')
        this.$form.off('submit.formSubmit')
    }
}
