import $ from 'jquery'
import Choices from 'choices.js'
export default class selectChoices {
    constructor (element) {
        this.$element = $(element)
        this.$select = this.$element.find('select')
        this.isMultiple = this.$select.prop('multiple')

        this.options = {}
        this.options.sort = !!this.$element.data('sort')
        this.options.choicesHideFilter = !!this.$element.data('choicesHideFilter')
        this.options.searchFields = ['label']
        this.options.searchResultLimit = 999999
        this.choices = null
        this.initialize()
    }

    getChoicesOptions () {
        const options = {
            searchEnabled: !this.options.choicesHideFilter,
            allowHTML: false,
            removeItemButton: false,
            itemSelectText: '',
            searchResultLimit: this.options.searchResultLimit,
            searchFields: this.options.searchFields,
            searchPlaceholderValue: 'Suchbegriff',
            noResultsText: 'Keine Ergebnisse.',
            shouldSort: this.options.sort
        }
        if (this.isMultiple) {
            options.removeItemButton = true
            options.noChoicesText = 'Keine Optionen verfügbar.'
        }
        return options
    }

    initialize () {
        this.choices = new Choices(this.$select.get(0), this.getChoicesOptions())

        // set to data - so another js can grab tghe choices instance
        this.$select.data('choicesInstance', this.choices)

    // Temporary disabled - we should show the selected element...
    // this.choices.passedElement.element.addEventListener('showDropdown', event => {
    //   const $dropdown = $(this.choices.dropdown.element)
    //   $dropdown.find('.choices__item').removeClass('is-highlighted')
    // }, false)
    }
    destroy () {

    }
}
